import extractUrls from 'extract-urls';
import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactTinyLink } from 'react-tiny-link';
import remarkGfm from 'remark-gfm';
import newsData from '../../data/news.json';
import { ContentContainer } from '../atoms/ContentContainer';
import { TopPagePoster } from '../molecules/TopPagePoster';
import sectionTracker from '../../util/SectionTracker';

const News: React.FC = () => {
  const { newsPost } = useParams();
  const navigate = useNavigate()
  useEffect(() => {
    if (newsPost) {
      navigate('/news#' + encodeURIComponent(newsPost), { replace: true });
    }
  }, []);

  return (
    <>
      <div className="bg-white">
        <TopPagePoster
          image={'/img/news/news.jpg'}
          imageAlt={'Sunlit Sea News'}
          headingText="News"
          subHeadingText={[
            'Read our latest news below',
            'Follow us on LinkedIn to stay updated',
          ]}
        />
        <ContentContainer className="flex flex-col py-16 lg:py-32 gap-y-16 lg:gap-y-32">
          {
            newsData.map(({ title, date, texts, images }, newsIndex) => {
              if (!images) images = [];
              const [headImage, ...tailImages] = images;
              const urls = texts.flatMap(extractUrls).filter((url) => url);
              return (
                <section key={encodeURIComponent(title)} className={encodeURIComponent(title) + ' flex flex-col lg:flex-row p-4 ' + ((newsIndex % 2 === 1) ? 'bg-gray-100' : '')}>
                  <div className="flex flex-col lg:mr-12 lg:w-1/2 default-heading">
                    <h2 className="" ref={sectionTracker('/news', undefined, false)}>{title}</h2>
                    <div className="text-sm">{date}</div>
                    {texts.map((text, textIndex) => (
                      <div key={'text' + textIndex} className="mt-8 default-body">
                        <ReactMarkdown
                          className={'[&>p>a]:underline [&>p>a]:hover:text-support-light [&>p>a]:text-support-dark'}
                          remarkPlugins={[remarkGfm]}>
                          {text}
                        </ReactMarkdown>
                      </div>
                    ))}
                  </div>
                  <div className="grid justify-center grid-cols-1 gap-2 lg:grid-cols-1 lg:gap-4">
                    {headImage &&
                      <div>
                        <div className="flex max-w-[616px]">
                          <img className="object-contain" src={'/img/news/' + headImage} alt={headImage} />
                        </div>
                      </div>
                    }
                    {tailImages.length > 0 &&
                      <div className="grid justify-center grid-cols-2 gap-2 lg:grid-cols-2 lg:gap-4">
                        {tailImages.map((image, imageIndex) => (
                          <div key={'image' + imageIndex} className="flex flex-row w-full gap-x-4 2xl:gap-x-8">
                            <div className="max-w-[300px] 3xl:max-w-full">
                              <img className="object-contain" src={'/img/news/' + image} alt={image} />
                            </div>
                          </div>
                        ))}
                      </div>
                    }
                    {urls.length > 0 &&
                      <div className={'[&>a]:flex'}>
                        {urls.map((url, urlIndex) => (
                          <ReactTinyLink
                            key={'url' + urlIndex}
                            cardSize="large"
                            showGraphic={true}
                            maxLine={2}
                            minLine={1}
                            proxyUrl="/api/proxy"
                            url={url as string}
                          />
                        ))}
                      </div>
                    }
                  </div>
                </section>
              )
            })}
        </ContentContainer>
      </div>
    </>
  );
};

export default News;