export const TIMELINE_GRAPH_CONSTANTS = {
  annotationLineStrokeWidth: 2,
  annotationIconDiameter: 17,
  axisThickness: 44,
  eventBoxWidth: 205,
  eventBoxVerticalPadding: 4,
  eventBoxHorizontalPadding: 4,
  eventBoxVerticalPaddingSmall: 8,
  eventBoxVerticalPaddingNormal: 16,
  eventBoxLineHeight: 21,
  eventMarkerWidth: 15,
  eventsBaseTopOffset: 16,
  generalPadding: 8,
  initialGraphHeight: 400,
  maximumVerticalLIncrement: 1.5, // must be >1
  maximumVerticalLTotalIncrease: 4,
  verticalBreakpointWidth: 1024,
  yearAxisVerticalWidth: 40,
  yearAxisHorizontalHeight: 44,
};
