import React, { useEffect } from 'react';

import { Route, Routes, useLocation } from 'react-router-dom';
import Main from './pages/Main';
import Product from './pages/Product';
import Services from './pages/Services';
import Technology from './pages/Technology';
import News from './pages/News';
import AboutUs from './pages/AboutUs';
import Careers from './pages/Careers';
import Investors from './pages/Investors';
import Contact from './pages/Contact';
import ReactGA from 'react-ga';
import prop from '../util/Prop';

export const AppRoutes: React.FC = () => {
  const location = useLocation();
  useEffect(() => {
    if (prop.ga) {
      if (location.pathname.substring(1).indexOf('/') === -1) {
        //only log the main pages
        console.log('ga.pageview(' + location.pathname + ')');
        ReactGA.pageview(location.pathname);
      }
    }
  }, [location]);

  return (
    <Routes>
      <Route path='/' element={<Main />} />
      <Route path='/product' element={<Product />} />
      <Route path='/services' element={<Services />} />
      <Route path='/technology' element={<Technology />} />
      <Route path='/news' element={<News />} />
      <Route path='/news/:newsPost' element={<News />} />
      <Route path='/about_us' element={<AboutUs />} />
      <Route path='/careers' element={<Careers />} />
      <Route path='/investors' element={<Investors />} />
      <Route path='/contact' element={<Contact />} />
    </Routes>
  );
};
