import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';


function ScrollTo() {
  const location = useLocation();
  useLayoutEffect(() => {
    setTimeout(() => {
      let scrollToOffset = -1;
      if (location.hash !== '') {
        const elems = document.getElementsByClassName(location.hash.substring(1));
        if (1 === elems.length) {
          const elemPositionConsideringHeading = (elems[0] as HTMLElement).offsetTop - 30;
          if (window.pageYOffset !== elemPositionConsideringHeading) {
            scrollToOffset = elemPositionConsideringHeading;
          }
        }
      }
      if (-1 === scrollToOffset) {
        if (window.pageYOffset !== 0) {
          scrollToOffset = 0;
        }
      }
      if (-1 !== scrollToOffset) {
        window.scrollTo(0, scrollToOffset);
      }
    });
  }, [location]);

  return null;
}

export default ScrollTo;