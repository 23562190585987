import React from 'react';

import { BrowserRouter } from 'react-router-dom';

import { AppRoutes } from './AppRoutes';
import SiteLayout from './pages/SiteLayout';
import ScrollTo from './ScrollTo';
import { SizeContext } from './util/SizeContext';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <SizeContext>
        <ScrollTo />
        <SiteLayout>
          <AppRoutes />
        </SiteLayout>
      </SizeContext>
    </BrowserRouter>
  );
};

export default App;
