import React from 'react';
import WhyInvest from '../molecules/investors/WhyInvest';
import Governance from '../molecules/investors/Governance';
import Costs from './../molecules/investors/Costs';
import Marketing from './../molecules/investors/Marketing';
import Patent from './../molecules/investors/Patent';
import { TopPagePoster } from '../molecules/TopPagePoster';
import { ContentContainer } from '../atoms/ContentContainer';

const Investors: React.FC = () => {
  return (
    <>
      <div className='bg-white'>
        <TopPagePoster
          image={'/img/investors/investors_poster.jpg'}
          imageAlt={'floating solar panels'}
          headingText='Investors'
          subHeadingText={[
            "A new technology for floating solar, enabling access to near-shore and inland locations " +
            "at an unparalleled price per kilowatt-hour. The product is proven and the company is " +
            "gearing up it's production capability to meet a rapidly increasing demand.",
          ]}
        />
        <ContentContainer>
          <div className="flex flex-col py-16 gap-y-16 lg:py-32 lg:gap-y-32">
            <WhyInvest />
            <Governance />
            <Marketing />
            <div className="flex flex-col gap-16 lg:flex-row">
              <Costs />
              <Patent />
            </div>
          </div>
        </ContentContainer>
      </div>
    </>
  );
};

export default Investors;
