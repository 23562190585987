import React from 'react';
import sectionTracker from '../../util/SectionTracker';
import { ContentContainer } from '../atoms/ContentContainer';
import { TopPagePoster } from '../molecules/TopPagePoster';

const Services: React.FC = () => {
  return (
    <>
      <div className="bg-white">
        <TopPagePoster
          image={'/img/product/wavetank.jpg'}
          imageAlt={'Services'}
          headingText="Services"
          subHeadingText={[
            'Develop your floating solar project with our expertise',
          ]}
        />
        <ContentContainer className="flex flex-col py-8 lg:py-16 gap-y-8 lg:gap-y-16">
          <section className="flex flex-col lg:flex-row">
            <div className="flex flex-col lg:mr-12 lg:w-1/2 default-heading">
              <h2 className="" ref={sectionTracker('/services')}>Services within floating solar</h2>
              <div className="mt-8 default-body">
                Sunlit Sea offers a range of services related to floating solar projects. These are typically charged for hour-by-hour and can alternatively be a part of a product sale or be charged for at a fixed price.
              </div>
              <div className="mt-8 default-body">
                Our service categories are: solar, hydrodynamic and product development. See each section below for further details.
              </div>
              <div className="mt-8 default-body">
                Get in touch for a chat about how we can assist in realizing your project:{' '}
                <a
                  className="underline text-support-dark"
                  href="mailto:post@sunlitsea.no?subject=services"
                >
                  post@sunlitsea.no
                </a>
              </div>
            </div>
            <div className="flex max-w-[500px] lg:max-w-full mx-auto mt-8 lg:min-h-full lg:w-1/2 lg:mt-0 ">
              <div className="w-full my-auto">
                <img
                  src={'/img/services/floating_solar.jpg'}
                  className="object-contain"
                  alt="Floating solar services"
                />
              </div>
            </div>
          </section>
          <section>
            <div className="flex flex-col-reverse justify-between lg:flex-row xl:mt-8">
              <img
                className="object-contain mt-4 lg:mt-0 lg:w-1/2 lg:max-w-full max-w-full sm:max-w-[500px] mx-auto"
                src={'/img/services/solar.jpg'}
                alt="solar"
              />
              <div className="flex flex-col default-body lg:pl-8 ">
                <div className="default-heading" ref={sectionTracker('/services')}>Solar services</div>
                <div className="mt-8 default-body">
                  <ul className="ml-8 list-disc">
                    <li>Local energy communities, peak reduction</li>
                    <li>Connection between different energy carriers, thermal, electrical, hydrogen</li>
                    <li>Procurement, public, announcements / tender documents, evaluation</li>
                    <li>Project management: research projects and commercial energy projects</li>
                    <li>Analyzes: HOMERpro, PVsyst, customized scripting</li>
                    <li>Solar power plant: pre-feasibility study, feasibility study, technology evaluation, tendering, owner’s engineer, entrepreneur’s engineer</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="flex flex-col lg:flex-row">
            <div className="flex flex-col lg:mr-12 lg:w-1/2 default-heading">
              <h2 className="" ref={sectionTracker('/services')}>Hydrodynamic services</h2>
              <div className="mt-8 default-body">
                <ul className="ml-8 list-disc">
                  <li>General hydrodynamic and MetOcean consulting</li>
                  <li>Establish and evaluate wave, wind, and current design criteria</li>
                  <li>Hydrodynamic design loads: assessments and review</li>
                  <li>Mooring design criteria and design loads: assessment and review</li>
                  <li>Green sea and slamming loads: assessments and review</li>
                  <li>Wave tank testing: planning, follow up, processing and evaluation of results, and review</li>
                </ul>
              </div>
            </div>
            <div className="flex max-w-[500px] lg:max-w-full mx-auto mt-8 lg:min-h-full lg:w-1/2 lg:mt-0 ">
              <div className="w-full my-auto">
                <img
                  src={'/img/product/panels.jpg'}
                  className="object-contain"
                  alt="Floating solar panels"
                />
              </div>
            </div>
          </section>
          <section>
            <div className="flex flex-col-reverse justify-between lg:flex-row xl:mt-8">
              <img
                className="object-contain mt-4 lg:mt-0 lg:w-1/2 lg:max-w-full max-w-full sm:max-w-[500px] mx-auto"
                src={'/img/about/culture.jpg'}
                alt="culture"
              />
              <div className="flex flex-col default-body lg:pl-8 ">
                <div className="default-heading" ref={sectionTracker('/services')}>Product development services</div>
                <div className="mt-8 default-body">
                  <ul className="ml-8 list-disc">
                    <li>Industrialization, production optimization, requirement specification for factory cells</li>
                    <li>Product development related to floating solar</li>
                    <li>Product design, prototyping, testing</li>
                    <li>Finite element simulation of loads and structural integrity</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

        </ContentContainer>
      </div>
    </>
  );
};

export default Services;
