import { useInView } from 'react-cool-inview';
import ReactGA from 'react-ga';
import prop from '../util/Prop';

const timerIds = new Map<string, NodeJS.Timeout>();

function toPath(text: string, alternative?: string, lowerCased = true): string {
    if (alternative) {
        return alternative;
    } else {
        if (lowerCased) {
            const joined = text.match(/[a-zA-Z0-9]*/g)?.filter(a => 0 < a.length).join('_').toLowerCase();
            return joined ? joined : '';
        } else {
            return encodeURIComponent(text);
        }
    }
}

const sectionTracker = (page?: string, alternative?: string, lowerCased = true, action?: (key: string) => void, timeoutMillis?: number) => {
    const realPage = page ? page : '';
    const realAction = action ? action : (key: string) => {
        if (prop.ga) {
            console.log('pageview(' + realPage + '/' + key + ')');
            ReactGA.pageview(realPage + '/' + key);
        }
    };
    const realTimeoutMillis = timeoutMillis ? timeoutMillis : 1000;
    const uw = {
        threshold: 1,
        onChange: ({ observe, unobserve }) => {
            unobserve();
            observe();
        },
        onEnter: ({ entry }) => {
            const key = toPath(entry.target.innerHTML, alternative, lowerCased);
            const timerId = timerIds.get(key);
            if (entry.target.innerHTML && !timerId) {
                timerIds.set(key, setTimeout(() => {
                    realAction(key);
                    timerIds.delete(key);
                }, realTimeoutMillis));
            }
        },
        onLeave: ({ entry }) => {
            const key = toPath(entry.target.innerHTML, alternative, lowerCased);
            const timerId = timerIds.get(key);
            if (timerId) {
                clearTimeout(timerId);
                timerIds.delete(key);
            }
        }
    };
    return useInView(uw).observe;
};
export default sectionTracker;
