import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/App';
import './styles/index.scss';
import ReactGA from 'react-ga';
import prop from './util/Prop';


const container = document.getElementById('root');
// eslint-disable-next-line
const root = createRoot(container!);

if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID && process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID !== 'NOT_SET') {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID, {
        //        gaAddress: 'http://localhost:3000/api/ga',
        //debug: true,
        gaOptions: {
            siteSpeedSampleRate: 100
        }
    });
    prop.ga = true;
    console.log('ga.initialize');
}

root.render(<App />);