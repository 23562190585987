import React from 'react';
import sectionTracker from '../../../util/SectionTracker';

const Costs: React.FC = () => {
  return (
    <>
      <div className="flex flex-col lg:w-1/2 gap-y-4 3xl:gap-y-8 default-body">
        <h2 className="default-heading" ref={sectionTracker('/news')}>Costs with floating solar power</h2>
        <div>
          CAPEX, OPEX and LCOE * for projects in floating solar power at sea are often considered by
          analysts to be dominated by: long-term installation processes with low quality, marine
          fouling and pollution, uncertainty about the effect of long-term operation, expensive
          financing due to risk, and a number of other factors.
        </div>
        <div>
          Sunlit Sea has an efficient production process, quality assurance, installation
          procedure and predictable maximum loads on the anchoring system, which means that CAPEX is
          somewhat lower than the competition.
        </div>
        <div>
          OPEX is positively affected by the use of smart materials and material processing to prevent
          marine fouling, and also the design itself of the system itself which
          facilitates easy operation and maintenance.
        </div>
        <div>
          The big return-of-investment in our system is however obtained through a very low LCOE, due to: the rigidity of the float, reducing panel degradation; efficient bonding techniques, reducing water ingress; direct contact with water, increasing electricity production and preventing panel degradation over time; and, prefabrication whole strings, improving quality and error-prone field work. Furthermore, our unique work within the marine grade performance ratio adds up to significantly lower financial risk Sunlit Sea projects.
        </div>
        <div className="text-base xl:text-xl">
          * CAPEX = capital expenditures, OPEX = operating expenses, LCOE = levelized cost of electricity over the
          lifetime of an installation.
        </div>
      </div>
    </>
  );
};

export default Costs;
