import React from 'react';
import PositionTile from './../atoms/PositionTile';
import careersData from '../../data/careers.json';
import { TopPagePoster } from '../molecules/TopPagePoster';
import { ContentContainer } from '../atoms/ContentContainer';
import sectionTracker from '../../util/SectionTracker';

const Careers: React.FC = () => {
  const positionsAvailable = careersData
    .map((item) => item.positionsOpened)
    .reduce((prev, next) => prev + next);

  return (
    <div className="bg-white">
      <TopPagePoster
        image={'/img/careers/person-soldering.jpg'}
        imageAlt={'person soldering'}
        headingText="Careers in Sunlit Sea"
        subHeadingText={[
          'Sunlit Sea is in rapid growth, and the need for both industry professionals and manual labor is ever present. ' +
          'Come join our journey to supply the world with clean, water based solar energy.',
        ]}
      />

      <ContentContainer className="flex flex-col gap-16 pb-16 mt-16">
        <div className="flex flex-col-reverse gap-8 lg:gap-16 lg:flex-row">
          <section className="flex flex-col-reverse items-start gap-8 lg:flex-col lg:w-1/2">
            <img
              className="object-contain "
              src={'/img/careers/team-working-in-lab.jpg'}
              alt="Team working in lab"
            />
            <div className="flex flex-col 2xl:mt-20">
              <h2 className="mt-8 default-heading" ref={sectionTracker('/careers')}>Why Sunlit Sea?</h2>
              <ul className="mt-8 default-body lg:pb-8 ">
                <li className="ml-6 list-disc">Builds novel ground-breaking technology</li>
                <li className="ml-6 list-disc">Sets the standard for the industry</li>
                <li className="ml-6 list-disc">
                  Develops an extremely potent tool for installing significant amounts of
                  renewable energy
                </li>

                <li className="ml-6 list-disc">
                  The only player that creates a fully integrated product for the FPV industry
                </li>
                <li className="ml-6 list-disc">Close to the research community and hi-tech industry</li>
                <li className="ml-6 list-disc">
                  Frequent implementation of research in every-day work
                </li>
                <li className="ml-6 list-disc">
                  Take part in a process {"'"}from idea to final product{"'"}
                </li>
                <li className="ml-6 list-disc">Opportunity to join a fantastic journey</li>
              </ul>
            </div>
          </section>

          <section className="flex flex-col items-start gap-8 lg:gap-16 lg:w-1/2">
            <div className="flex flex-col ">
              <h2 className="mt-4 default-heading md:mt-0" ref={sectionTracker('/careers')}>Who we are</h2>
              <div className="mt-4 default-body">
                Sunlit Sea is on a mission to deliver the most bankable, compelling and robust
                floating solar solution for inland and near-shore applications. We are strong
                believers that an inspiring, including and goal-driven culture is the best way
                to accomplish this. We set our goals together, fail together and learn together.
              </div>
              <div className="mt-4 default-body">
                In Sunlit Sea you find both PhD{"'"}s and self-taught production engineers. As an
                employee you will also get to interact with a wide range of fantastic people in
                our partner network. Innovating in the discipline of floating solar requires a
                broad perspective - while our hydrodynamics expert Bjørn can model complex sea
                states perfectly, when it comes to calculating the marine grade performance ratio
                he still needs to join forces with programmer and electrician Jan-Marius from
                Kodeworks and scientist Vilde from Sintef.
              </div>
              <div className="mt-4 default-body">
                Sunlit Sea AS has developed a novel technology for floating solar installations,
                and are now ready for mercantile deliveries. This does not mean our innovative
                work is done. We are now focusing our efforts in improving the production line
                to obtain higher throughput in our factory and reduced costs. Our chief of
                industrialization Guillaume is leading this work. Would you like to help us?
              </div>
            </div>
            <img
              className="object-contain"
              src={'/img/careers/team-working-at-sea.jpg'}
              alt="Team working at sea"
            />
          </section>
        </div>
        <section>
          <h2 className="text-5xl md:text-6xl md:text-center font-headline" >
            {positionsAvailable} job openings available
          </h2>
          <div className="mt-8 text-xl md:text-3xl md:text-center">
            <p>We are currently looking for people with the following profiles.</p>
            <p>
              You may also send us an open application at{' '}
              <a
                className="font-bold text-gray-900"
                href={'mailto:post@sunlitsea.no?subject=' + encodeURIComponent('Open application')}
              >
                post@sunlitsea.no
              </a>
              .
            </p>
          </div>

          <div className="grid grid-cols-1 gap-4 mt-16 mb-16 md:mt-16 lg:grid-cols-3 sm:grid-cols-2" ref={sectionTracker('/careers', 'positions')}>
            {careersData.map(
              ({ positionsOpened, position, description, sector, location, trade, keywords }) => (
                <PositionTile
                  positionsOpened={positionsOpened}
                  position={position}
                  description={description}
                  sector={sector}
                  location={location}
                  trade={trade}
                  keywords={keywords}
                  key={position}
                />
              )
            )}
          </div>
        </section>
      </ContentContainer>
    </div>
  );
};

export default Careers;
